import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class PositionsApiService extends ApiService {
  constructor() {
    super();
  }

  public getPositions$(): Observable<PositionDto[]> {
    return this.get('/positions');
  }

  createPosition$(name: string): Observable<PositionDto> {
    return this.put(`/positions/${name}`);
  }
}

export interface PositionDto {
  id: number;
  name: string;
}

export const apiPositionsProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiPositions.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiPositions.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiPositions.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiPositions.scopes.write],
  },
];
